/* eslint-disable import/no-anonymous-default-export */
export default {
  application: { errors: [] },
  sidebarMenu: [],
  manageLeads: [],
  campaignsData: [],
  sidebarShow: "responsive",
  isLogin: false,
  leadsAssignMode: false,
  loginInfo: {},
  orderLeads: {
    leadGroups: [],
    shoppingCart: [],
    oreredQuantity: null,
    CartInformation: {},
    levels: [],
    greeting: "",
    isDeletedFromHeaderCart: false,
    activeCartId: "",
    filteredLeadTypesBasedOnParam: [],
  },
  states: [],
  countys: [],
  leadTypes: [],
  isHavePermission: false,
  loadingStatus: false,
  agents: {
    campaignsCountObj: {
      activeUserLifeCallCampaignCount: null,
      inactiveUserLifeCallCampaignCount: null,
      activeUserHealthCallCampaignCount: null,
      inactiveUserHealthCallCampaignCount: null,
    },
    toggleDisableStatus: false,
  },
  producersList: [],
  coupons: {
    couponInformation: null,
  },
  eGifts: {},
  vendorCredits: {},
  creditRequestStatuses: [],
  ishavingPaymentmethods: false,
  isLogoutModal: false,
  voucherAmount: 0,
  isAddFundsModal: false,
  isAutomateModal: false,
  isAutomateAndSaveMode: false,
  showAutomateAndSaveTurnOffPopup: false,
  isContactSupportModal: false,
  agentPaymentInfo: null,
  unapprovedAgents: [],
  togglePreferences: [],
  purchaseTypes: [],
  products: [],
  productCategories: [],
  rtlLeadTypes: [],
  customAdCampaignTypes: [],
  customAdCreativeNames: [],
  vouchers: {},
  cashDetails: {},
  isGetCreditDetails: false,
  isAddFundsForRealtime: false,
  isReloadPayments: false,
  refreshCampaignsCount: false,
  transactionHistoryState: {
    transactionsData: [],
    transactionsTotalCount: null,
    loading: false,
    automatedOrdersLoading: false,
    automatedOrdersData: [],
    automatedOrdersTotalCount: null,
    inventoryStatuses: null,
  },
  leads: {
    notes: [],
    reminders: [],
    notesTotalCount: 0,
    reminderTotalCount: 0,
    notesLoading: false,
    remindersLoading: false,
  },
  userFeatures: {
    CanManageLeadRefund: false,
    CanUpdateLead: false,
    CanViewLeadAll: false,
    CanRealTimeLeads: false,
    CanAdCampaigns: false,
  },
  applicationBusinessUnits: null,
  userBusinessUnits: null,
  daysOfWeek: null,
  faq: {
    keywordSearched: "",
  },
  userAppIntegrations: null,
  isBannersVisible: true,
  layoutHeights: {
    mobileToggleHeight: 0,
    headerHeight: 0,
    bannersHeight: 0,
    wrapperMarginTop: 0,
    blockedReasonBannerHeight: 0,
  },
  userProfiles: {
    userprofilesLoading: false,
    userprofiles: null,
    userprofilesTotalCount: 20,
    userProfileStatuses: null,
  },
  isListScreenError: false,
  isViewEditScreenError: false,
  welcomeModalDetails: {
    loading: false,
    modalData: null,
    error: null,
    isDoNotShowChecked: false,
  },
};
